import React from "react";
import "./Header.css";

const socialsArray = [
  {
    path: "./telegram.svg",
    link: "https://t.me/+IXdmiGMx2pw1ZjRk",
    name: "Telegram",
  },
  {
    path: "./discord.svg",
    link: "https://discord.gg/RfTWXqYqNa",
    name: "Discord",
  },
  {
    path: "./x.svg",
    link: "https://x.com/DetardioGods?t=UDQFLTEqSdWyTGavQd1hmQ&s=09",
    name: "Twitter",
  },
  
  {
    path: "./sniper.svg",
    link: "https://www.sniper.xyz/collection/detardio_gods?tab=sell",
    name: "Sniper",
  },
  
  {
    path: "./tensor.svg",
    link: "https://www.tensor.trade/trade/detardio_gods",
    name: "Tensor Trade",
  }
  
];
console.log(socialsArray);
function Header() {
  return (
    <header>
      <div className="logoContainer">
        <a href="/"> <img className="logo" src="./Logo.png" /></a>
      </div>
      <div className="navBar">
        {socialsArray.map((social) => {
          return (
            <div className="navItem" title={social.name}>
              <a href={social.link}>
                <img className="navItemLogo" src={social.path} />
              </a>
            </div>
          );
        })}
      </div>
    </header>
  );
}
export default Header;
