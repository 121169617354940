import logo from "./logo.svg";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Placeholder from "./placeholder/placeholder";
import Header from "./header/Header";
import Footer from "./footer/Footer";
// import MainLogo from "../public/Main Logo.png";

function App() {
  return (
    <div className="App">
      <img className="mainBG" src="./Stars bg.png"/>
      <img className="overlayBG" src="./Gradient.png"/>
      <Header/>
      <Router>
        <Routes>
          <Route path="/" element={<Placeholder/>}></Route>
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;