import React from "react";
import "./Placeholder.css";

function Placeholder() {
  return (
    <div className="placeHolder">
      <div className="banner">
        <img src="./MainLogo.png" />
        <h3>Embrace chaos or fuck off</h3>
      </div>
    </div>
  );
}

export default Placeholder;
